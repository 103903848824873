import providerRoutes from "./providers"
import consumerRoutes from "./consumers"
import manageRoutes from "./manage"
import prepareRoutes from "./prepare"

export default [
  {
    path: "",
    name: "",
    component: () => import("@/layout/DashboardLayout.vue"),
    redirect: "/home",
    meta: {
      isProtected: true
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/modules/home/pages/Home.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Home",
              translationKey: "common.home",
              routeName: "home"
            }
          ]
        }
      },
      {
        path: "/policies",
        name: "policies",
        component: () => import("@/modules/policies/pages/PoliciesView.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Home",
              translationKey: "common.home",
              routeName: "home"
            }
          ]
        }
      },
      ...providerRoutes,
      ...manageRoutes,
      ...prepareRoutes,
      ...consumerRoutes
    ]
  }
]
