<template>
  <scale-notification
    type="toast"
    :variant="variant"
    opened
    :alignment="alignment"
    class="t-notification"
    :heading="notificationText"
    dismissible
    @scale-close="dismiss"
  >
    <template v-if="notificationBody?.length" slot="text" class="text">
      {{ notificationBody }}
    </template>
    <template v-else-if="notificationList?.length" slot="text">
      <scale-list v-if="notificationList">
        <scale-list-item v-for="notificationMsg in notificationList" :key="notificationMsg">
          {{ notificationMsg }}
        </scale-list-item>
      </scale-list>
    </template>
  </scale-notification>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onUnmounted, ref } from "vue"
import { type INotification, NotificationType } from "../INotification"
import { useI18n } from "vue-i18n"
import { DEFAULT_DISMISS_TIMEOUT } from "../INotification"

const currentInstance = getCurrentInstance()

const emit = defineEmits(["dismiss"])
const { t } = useI18n()
const props = defineProps({
  notification: {
    type: Object as () => INotification,
    required: true
  },
  timeout: {
    type: Number,
    default: DEFAULT_DISMISS_TIMEOUT
  },
  alignment: {
    type: String,
    default: "top-right"
  }
})

const dismissTimer = ref(0)

const notificationBody = computed((): string | undefined => {
  return props.notification.body
})

const notificationText = computed((): string => {
  return props.notification.text === "genericError"
    ? t(props.notification.text)
    : props.notification.text
})

const notificationList = computed((): string[] | undefined => {
  return props.notification.list
})

const variant = computed(() => {
  let variant = "success"
  switch (props.notification.type) {
    case NotificationType.SUCCESS:
      break
    case NotificationType.ERROR:
      variant = "danger"
      break
    case NotificationType.HINT:
      variant = "informational"
      break
    case NotificationType.WARNING:
      variant = "warning"
      break
  }
  return variant
})

// trigger parent and emit dismiss event
function dismiss() {
  emit("dismiss", props.notification)
  currentInstance?.parent?.emit("dismiss", props.notification)
}

dismissTimer.value = window.setTimeout(dismiss, props.timeout)

onUnmounted(() => {
  window.clearTimeout(dismissTimer.value)
})
</script>
<style scoped lang="scss">
.text {
  display: block;
  word-break: break-word;
}

.t-notification {
  &::part(heading) {
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}
</style>
