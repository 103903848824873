import type { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"

const providerRoutes: RouteRecordRaw[] = [
  {
    path: "/provider",
    name: "provider",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.provider.configure.path,
        name: ProtectedRoutes.provider.configure.pathName,
        component: () => import("@/modules/create-offer/pages/ConfigureOffer.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Provide",
              translationKey: "sideMenu.provide",
            },
            {
              title: "Provide data",
              translationKey: "sideMenu.publishData",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.provider.success.path,
        name: ProtectedRoutes.provider.success.pathName,
        component: () => import("@/modules/create-offer/pages/OfferPublishSuccess.vue"),
        meta: {
          isProtected: true
        }
      }
    ]
  }
]

export default providerRoutes
